<template>
  <div>
    <b-card header-tag="header" footer-tag="footer" no-body>
      <b-card bg-variant="primary" style="margin-bottom: 0px; background-color: #3c9ca5!important;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          <b-row>
            <b-col cols="1">
              Log Bilgisi
            </b-col>
            <b-col cols="3" v-if="!d_showMode">
              <b-form inline>
                <b-form-input type="date" id="d_startingDate" v-model="d_startingDate"></b-form-input>
                <b-form-input type="date" id="d_endingDate" v-model="d_endingDate"></b-form-input>
              </b-form>
            </b-col>
            <b-col cols="6" v-if="!d_showMode">
              <b-form-checkbox-group v-model="d_selectedLogTypes">
                <template v-for="log_type in d_allLogTypes">
                  <b-form-checkbox :name="log_type.value" :value="log_type.value">{{ log_type.label }}</b-form-checkbox>
                </template>
              </b-form-checkbox-group>
            </b-col>
            <b-col :cols="d_showMode ? '10' : '2'">
              <b-button class="pull-right" size="sm" variant="primary" @click="d_showMode ? d_showMode = false : d_showMode = true"><i class="fa fa-edit"></i></b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <template v-if="d_showMode">
          <b-row style="padding: 3px;">
            <b-col sm="12" md="4">
              <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  <i class="fa fa-building-o"></i> Tarih Aralığı Seçimi
                </b-card-header>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Başlama Tarihi" label-for="d_startingDate" :label-cols="3">
                      <b-form-input type="date" id="d_startingDate" v-model="d_startingDate"></b-form-input>
                    </b-form-group>
                  </b-col>
                <b-row>
                </b-row>
                  <b-col cols="12">
                    <b-form-group label="Bitiş Tarihi" label-for="d_endingDate" :label-cols="3">
                      <b-form-input type="date" id="d_endingDate" v-model="d_endingDate"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col sm="12" md="4">
              <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  <i class="fa fa-building-o"></i> Log Türü Seçimi
                </b-card-header>
                <b-row>
                  <b-col cols="12">
                    <b-form-checkbox-group stacked v-model="d_selectedLogTypes">
                      <template v-for="log_type in d_allLogTypes">
                        <b-form-checkbox :name="log_type.value" :value="log_type.value">{{ log_type.label }}</b-form-checkbox>
                      </template>
                    </b-form-checkbox-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </template>
        <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;">
          <b-row>
            <b-col sm="12" md="4">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Filtreleme" label-for="filterTable" :label-cols="3">
                    <b-form-input type="text" id="filterTable" placeholder="Filtreleme yapabilirsiniz" v-model="filterTable"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12" md="4">
              <b-button variant="warning" size="md" style="width: 100%;" @click="get_logs()"><i class="fa fa-refresh"></i> güncelle</b-button>
            </b-col>
            <b-col sm="12" md="4">
              <strong> Log sayısı: {{ logListRows.length }} </strong>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </b-card>
    <b-card header-bg-variant="primary">
      <b-row class="" id="">
        <b-col cols="12">
          <b-pagination v-model="d_logResultPageData['current']" :total-rows="logListRows.length" :per-page="d_logResultPageData['per-page']" aria-controls="log-table"></b-pagination>
          <b-table id="log-table" :per-page="d_logResultPageData['per-page']" :current-page="d_logResultPageData['current']" head-variant="dark" striped :filter="filterTable" responsive stacked="/*sm*/" ref="ref_logList" bordered small hover :items="logListRows" :fields="logListColumns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-dblclicked="" @row-hovered="" @row-unhovered="">
            <template v-slot:cell(rowNumber)="row">
              {{ ((d_logResultPageData['current'] - 1) * d_logResultPageData['per-page']) + row.index + 1 }}
            </template>
            <template v-slot:cell(date)="row">
              {{ moment(row.item.date).format("DD/MM/YYYY HH:mm") }}
            </template>
            <template v-slot:cell(userNameAll)="row">
              {{ row.item.userFirstName + ' ' + row.item.userLastName }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogService from '@/services/log';
import store from '@/store';
import moment from 'moment';
// import {
//   default as Modal
// } from '@/components/widgets/Modal';

export default {
  name: 'Logs',
  components: {
    // Modal
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      d_logResultPageData: { 'current': 1, 'per-page': 100 },
      logListColumns: [{
        key: "rowNumber",
        label: " "
      }, {
        key: "date",
        label: "Tarih",
        sortable: true
      }, {
        key: "userNameAll",
        label: "Kullanıcı Adı",
        sortable: true
      }, {
        key: "hospitalGroup",
        label: "Hastane Grubu",
        sortable: true
      }, {
        key: "hospitalCode",
        label: "Hastane Kodu",
        sortable: true
      }, {
        key: "codeType",
        label: "Kod"
      }, {
        key: "info",
        label: "Bilgi"
      }, {
        key: "typeError",
        label: "Type Error"
      }, {
        key: "valueError",
        label: "Value Error"
      }],
      moment: moment,
      filterTable: '',
      logListRows: [],
      user: {},
      d_startingDate: '',
      d_endingDate: '',
      d_selectedLogTypes: ['error', 'information'],
      d_showMode: true,
      d_allLogTypes: [{ 'value': 'error', 'label': 'Error' }, { 'value': 'information', 'label': 'Information' }]
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_startingDate = moment(new Date()).format('YYYY-MM-DD');
    this.d_endingDate = moment(new Date()).format('YYYY-MM-DD');
  },
  mounted: function () {
    this.get_logs();
  },
  methods: {
    get_logs: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Loglar güncelleniyor.' } });
      if (this.d_selectedLogTypes.length === 0) {
        for (let i in this.d_allLogTypes) {
          this.d_selectedLogTypes.push(this.d_allLogTypes[i].value);
        }
      }
      LogService.getLogs({ 'log_type': this.d_selectedLogTypes, 'starting_date': this.d_startingDate, 'ending_date': this.d_endingDate })
        .then(resp => {
          this.d_showMode = false;
          if (resp.data.status === 'success') {
            this.logListRows = resp.data.result;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error', resp.data.message);
          }
        });
    }
  },
  watch: {}
}

</script>

