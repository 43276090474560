import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  getLogs (data) {
    return Vue.http.post(API_BASE_URL + 'log/getLogs', data);
  },
  getRequestLogs (data) {
    return Vue.http.post(API_BASE_URL + 'log/getRequestLogs', data);
  }
};
